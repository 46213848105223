<template>
  <div>
    <b-card>
      <b-button variant="primary" size="sm" v-b-modal.apply-leave v-if="getRole() != 'director'">
        Apply For Leave
      </b-button>

      <b-modal id="apply-leave" size="md" title="Apply For Leave" hide-footer>
        <form @submit.prevent="submit_form">
          <b-form-group label="Subject">
            <b-form-input id="subject" v-model="subject" type="text" placeholder="Subject" />
          </b-form-group>
          <b-form-group label="Employee" v-if="getRole() != 'staff'">
            <v-select v-model="employee" :options="employees" label="name" :reduce="(employee) => employee.id" />
          </b-form-group>
          <b-form-group label="Type">
            <v-select v-model="leave_type" :options="types" label="name" @input="getRemainingDay()" />
            <span class="mt-1">Remaining Days: {{ remainingDays }}</span>
          </b-form-group>
          <b-form-group label="Number of Days">
            <b-form-input id="number_of_days" v-model="number_of_days" @input="updateRemainingDays()" type="number"
              min="1" />
          </b-form-group>
          <b-row>
            <b-col md="6">
              <b-form-group label="From Date">
                <flat-pickr v-model="start" :config="config" placeholder="Start Date" class="form-control" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="To Date">
                <flat-pickr v-model="end" :config="config" placeholder="End Date" class="form-control" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group label="Handover">
            <v-select v-model="handover" :options="handovers" label="name" :reduce="(handover) => handover.id" />
          </b-form-group>
          <b-form-group label="Reason">
            <b-form-textarea id="reason" v-model="reason" placeholder="Reason" />
          </b-form-group>
          <b-form-group label="Attached File">
            <b-form-file id="file" v-model="file" placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..." />
          </b-form-group>

          <div class="text-right mt-2">
            <b-button variant="outline-danger" @click="$bvModal.hide('apply-leave')" class="mr-1"> Cancel </b-button>
            <b-button variant="primary" type="submit"> Submit </b-button>
          </div>
        </form>
      </b-modal>

      <div class="d-flex justify-content-between align-items-center mb-1 mt-1">
        <div class="d-flex align-items-center">
          <label for="" class="mr-1" style="font-size: 14px">Sort</label>
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-100" />
          <download-excel :data="exportItems" :fields="exportFields" :filename="'Employee'" :sheetname="'Employee'"
            class="cursor-pointer ml-1">
            <b-button variant="outline-secondary" size="sm" class="d-flex mr-1 export-btn">
              <feather-icon icon="FileIcon" size="14" class="text-success" />
              <label style="font-size: 14px; margin-left: 5px; cursor: pointer">Export</label>
            </b-button>
          </download-excel>
        </div>
        <div class="d-flex align-items-center">
          <label for="" class="mr-1" style="font-size: 14px">Search</label>
          <b-form-input id="searchInput" v-model="filter" size="sm" type="search" placeholder="Search" />
        </div>
      </div>

      <b-row>
        <b-col cols="12">
          <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
            :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
            :filter="filter" @filtered="onFiltered" class="header-wrap">
            <template #cell(name)="data">
              <span v-b-modal="'detail_employee_' + data.value">
                {{ data.value }}
              </span>
            </template>
            <template #cell(profile_photo_url)="data">
              <b-avatar :src="data.value" />
            </template>

            <template #cell(status)="data">
              <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge>
            </template>

            <template #cell(actions)="data">
              <b-button variant="outline-primary" size="sm" class="mr-1" @click="view('detail_leave', data.value)"
                v-if="getRole() != 'director'">
                <feather-icon icon="EditIcon" size="14" />
              </b-button>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <div class="d-flex justify-content-between align-items-center">
            <span>
              Showing
              {{ perPage > totalRows ? totalRows : perPage }} of {{ totalRows }} entries
            </span>
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
              class="my-0" />
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-modal id="detail_leave" title="Detail Leave" size="md" hide-footer>
      <b-row>
        <b-col md="12">
          <b-form-group label="Employee">
            <b-form-input v-model="leave.user.name" readonly />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Leave Type">
            <b-form-input v-model="leave.leave_type.name" readonly />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Start Date">
            <b-form-input v-model="leave.start_date" readonly />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="End Date">
            <b-form-input v-model="leave.end_date" readonly />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Handover">
            <b-form-input v-model="leave.handover.name" readonly />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Reason">
            <b-form-textarea v-model="leave.reason" readonly />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="text-right" v-if="getRole() == 'staff' && leave.status == 'pending'">
        <b-button size="sm" variant="danger" @click="view('delete_leave', leave)">Cancel Request</b-button>
      </div>

      <div class="text-right" v-if="getRole() != 'staff'">
        <b-button size="sm" variant="primary" @click="view('approve_leave', leave)">Approve?</b-button>
      </div>
    </b-modal>

    <b-modal id="delete_leave" title="Confirmation" size="sm" hide-footer>
      Are you sure to cancel this request?
      <div class="text-right mt-2">
        <b-button variant="outline-danger" @click="remove(leave.id)" class="mr-1" size="sm"> Cancel Now </b-button>
        <b-button variant="primary" @click="$bvModal.hide('delete_leave')" size="sm"> Close </b-button>
      </div>
    </b-modal>

    <b-modal id="approve_leave" title="Confirmation" size="md" hide-footer v-if="getRole() != 'staff'">
      Are you sure to approve this request?
      <div class="d-flex justify-content-between mt-2">
        <div>
          <b-button variant="outline-danger" @click="reject(leave.id)" class="mr-1" size="sm"> Reject </b-button>
        </div>
        <div>
          <b-button variant="primary" @click="approve(leave.id)" size="sm" class="mr-1"> Approve Now </b-button>
          <b-button variant="secondary" @click="$bvModal.hide('approve_leave')" size="sm"> Close </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<style>
.header-wrap {
  white-space: nowrap !important;
}

.export-btn {
  height: 2.142rem;
  background: #fff !important;
  border: 1px solid #d8d6de !important;
}
</style>

<script>
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import { debounce } from "lodash";

export default {
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      fields: [
        // {
        //   key: "subject",
        //   label: "Subject",
        // },
        {
          key: "name",
          label: "Name",
        },
        { key: "type", label: "Type", sortable: "true" },
        { key: "start_date", label: "Start Date", sortable: true },
        { key: "end_date", label: "End Date", sortable: "true" },
        { key: "approver", label: "Approver", sortable: "true" },
        // { key: "handover", label: "Handover", sortable: "true" },
        // { key: "reason", label: "Reason", sortable: "true" },
        { key: "status", label: "Status", sortable: "true" },
        { key: "created_at", label: "Created At", sortable: "true" },
        { key: "actions", label: "Actions" },
      ],
      items: [],
      status: [
        {
          pending: "Pending",
          approved: "Approved",
          rejected: "Rejected",
        },
        {
          pending: "light-warning",
          approved: "light-success",
          rejected: "light-danger",
        },
      ],

      // export excel
      exportFields: {
        Subject: "subject",
        Name: "name",
        "Number of Days": "number_of_days",
        "Start Date": "start_date",
        "End Date": "end_date",
        Approver: "approver",
        Handover: "handover",
        Reason: "reason",
        Type: "type",
        Status: "status",
        "Created At": "created_at",
      },
      exportItems: [],

      // config v-select
      config: {
        altInput: true,
        altFormat: "F j, Y",
      },

      // modal
      subject: "",
      employee: this.getAuthId(),
      leave_type: "",
      number_of_days: "",
      start: "",
      end: "",
      handover: "",
      reason: "",
      file: [],
      remainingDays: 0,

      employees: [],
      types: [],
      handovers: [],

      leave: {
        subject: "",
        user: "",
        leave_type: "",
        number_of_days: "",
        start: "",
        end: "",
        handover: "",
        reason: "",
        status: "",
        file: [],
      },
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    // fetch data
    this.fetchData();
  },

  methods: {
    view(name, data) {
      this.leave = data
      this.$bvModal.show(name)
    },

    getRemainingDay() {
      this.callApi({
        url: 'user-remaining-leaves/fetch',
        params: {
          user_id: this.hashid(this.employee)
        },
        success: (res) => {
          res.result.map((item) => {
            if (this.leave_type.id == parseInt(item.leave_type_id)) {
              if (this.leave_type.id == 5 || this.leave_type.id == 4) return this.remainingDays = '-'
              return this.remainingDays = parseInt(item.remaining_days)
            }
          })
        }
      })
    },

    updateRemainingDays: debounce(function () {
      if (this.number_of_days != "") return this.remainingDays = this.remainingDays - this.number_of_days
      return this.getRemainingDay()
    }, 500),

    fetchData() {
      this.callApi({
        method: "GET",
        url: "/leaves/fetch",
        success: (response) => {
          response.result.forEach((item) => {
            this.items.push({
              id: item.id,
              subject: item.subject,
              name: item.user.name,
              start_date: moment(item.start_date).format("DD MMMM YYYY"),
              end_date: moment(item.end_date).format("DD MMMM YYYY"),
              approver: item.approver.name,
              handover: item.handover ? item.handover.name : "-",
              reason: item.reason,
              type: item.leave_type.name,
              status: item.status,
              created_at: moment(item.created_at).format("DD MMMM YYYY"),
              actions: item,
            });

            this.exportItems.push({
              subject: item.subject,
              name: item.user.name,
              number_of_days: item.number_of_days,
              start_date: item.start_date,
              end_date: item.end_date,
              approver: item.approver.name,
              handover: item.handover ? item.handover.name : "-",
              reason: item.reason,
              type: item.leave_type.name,
              status: item.status,
              created_at: moment(item.created_at).format("DD MMM YYYY"),
            });
          });

          //
          this.totalRows = this.items.length;
        },
      });

      this.callApi({
        url: "/users/getUserByownDepartment",
        method: "GET",
        success: (response) => {
          this.employees = response.result;

          if (this.getRole() != "manager") {
            // delete all items except current user
            this.employees = this.employees.filter((item) => {
              return item.id == JSON.parse(localStorage.getItem("sw_auth_data")).id;
            });
          } else {
            // delete items hrcode 1101101
            this.employees = this.employees.filter((item) => {
              return item.hrcode !== "1101101";
            });
          }

          this.handovers = response.result;
          // delete items hrcode 1101101 and current user
          this.handovers = this.handovers.filter((item) => {
            return item.hrcode !== "1101101" && item.id != JSON.parse(localStorage.getItem("sw_auth_data")).id;
          });
        },
      });

      this.callApi({
        url: 'leave-types/fetch',
        method: 'get',
        success: (res) => {
          res.result.map((item) => {
            this.types.push(item)
          })
        }
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteEmployee(id) {
      this.callApi({
        method: "DELETE",
        url: "/users/delete/" + this.hashid(id),
        success: (response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Delete successfully",
              variant: "success",
            },
          });
          this.items = [];
          this.fetchData();
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to delete",
              variant: "error",
            },
          });
        },
      });
    },
    submit_form() {
      this.callApi({
        method: "POST",
        url: "/leaves/create",
        data: {
          user_id: JSON.parse(localStorage.getItem("sw_auth_data")).id,
          subject: this.subject,
          employee: this.employee.id,
          leave_type_id: this.leave_type.id,
          number_of_days: this.number_of_days,
          start_date: this.start,
          end_date: this.end,
          handover_id: this.handover,
          reason: this.reason,
          status: "pending",
        },
        success: (response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Create successfully",
              variant: "success",
            },
          });
          this.items = [];
          this.fetchData();
          this.$bvModal.hide("apply-leave");
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Submit failed",
              variant: "error",
            },
          });
        },
      });
    },

    approve(id) {
      this.callApi({
        method: "POST",
        url: "/leaves/update/" + this.hashid(id),
        data: {
          id: id,
          status: "approved",
        },
        success: (response) => {
          this.$bvModal.hide("detail_leave")
          this.$bvModal.hide("approve_leave")

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Approve successfully",
              variant: "success",
            },
          });
          this.items = [];
          this.fetchData();
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to approve",
              variant: "error",
            },
          });
        },
      });
    },

    reject(id) {
      this.callApi({
        method: "POST",
        url: "/leaves/update/" + this.hashid(id),
        data: {
          id: id,
          status: "rejected",
        },
        success: (response) => {
          this.$bvModal.hide("detail_leave")
          this.$bvModal.hide("approve_leave")

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Reject successfully",
              variant: "success",
            },
          });
          this.items = [];
          this.fetchData();
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to reject",
              variant: "error",
            },
          });
        },
      });
    },

    remove(id) {
      this.callApi({
        method: "DELETE",
        url: "/leaves/delete/" + this.hashid(id),
        success: (response) => {
          this.$bvModal.hide("detail_leave")
          this.$bvModal.hide("delete_leave")

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Remove successfully",
              variant: "success",
            },
          });
          this.items = [];
          this.fetchData();
        },
        error: (error) => {
          this.$bvModal.hide("delete_leave")
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to remove",
              variant: "error",
            },
          });
        },
      });
    },
  }
};
</script>